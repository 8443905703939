import Vue from 'vue'
import Vuex from 'vuex'
import axios from "@/axios.js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('token') || ''
  },
  getters: {
    token: state => state.token,
    formatDate: state => dateVal => {
      var date = new Date(dateVal);
      // Define month names
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      // Extract the components of the date
      const month = months[date.getMonth()]; // Get month name
      const day = date.getDate(); // Get day of the month
      const year = date.getFullYear(); // Get full year

      // Extract the components of the time
      let hours = date.getHours(); // Get hours
      const minutes = date.getMinutes().toString().padStart(2, "0"); // Get minutes and pad with zero if needed

      // Format the time in 24-hour format
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes}`;

      // Format the full date and time string
      return `${month} ${day} ${year} - ${formattedTime}`;

    }
  },
  mutations: {
    setToken(state, value) {
      state.token = value
    }
  },
  actions: {
    authRequest({ commit, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: payload.path,
          data: payload.data,
          method: "POST"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            sessionStorage.removeItem("token");
            reject(err);
          });
      });
    },
    delRequest({ commit, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        const token = getters.token;

        axios({
          url: `${payload.path}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) {
              if (err.response.status === 401) {
                dispatch('logout').then(() => {
                  location.href = "/login";
                });
              } else {
                reject(err)
              }
            } else {
              reject(err);
            }
          });
      });
    },
    putRequest({ commit, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        const token = getters.token;
        axios({
          url: payload.path,
          data: payload.data,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) {
              if (err.response.status === 401) {
                dispatch('logout').then(() => {
                  location.href = "/login";
                });
              } else {
                reject(err)
              }
            } else {
              reject(err);
            }
          });
      });
    },
    postRequest({ commit, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        const token = getters.token;
        axios({
          url: payload.path,
          data: payload.data,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) {
              if (err.response.status === 401) {
                dispatch('logout').then(() => {
                  location.href = "/login";
                });
              } else {
                reject(err)
              }
            } else {
              reject(err);
            }
          });
      });
    },
    getRequest({ commit, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        const token = getters.token;

        axios({
          url: `${payload.path}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) {
              if (err.response.status === 401) {
                dispatch('logout').then(() => {
                  location.href = "/login";
                });
              } else {
                reject(err)
              }
            } else {
              reject(err);
            }
          });
      });
    },
    logout({ commit }) {
      sessionStorage.removeItem("token");
      commit('setToken', '');
      // localStorage.removeItem("user");
      delete axios.defaults.headers.common["Authorization"];
      return Promise.resolve();
    },
  },
  modules: {
  }
})

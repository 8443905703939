<template>
  <div class="max-h-screen flex items-stretch min-h-screen">
    <div
      class="bg-realBlack text-white flex flex-col items-center justify-between relative w-2/12 pt-[6vh] pb-[4vh]"
    >
      <!-- <img src="@/assets/svgs/logoDynamic.svg" alt="" /> -->
      <div class="w-full">
        <div class="w-full flex justify-center mb-[6vh]">
          <DynamicLogo />
        </div>
        <div class="links">
          <router-link
            class="block"
            :to="{ name: menuItem.link_name }"
            v-for="menuItem in menuItems"
            :key="menuItem.link_name"
          >
            <button
              class="w-full hover:bg-white hover:text-realBlack flex items-center px-6 py-2 font-roboto gap-4 transition-all duration-500"
              :class="[
                currentRouteName === menuItem.link_name
                  ? 'bg-white text-realBlack'
                  : 'bg-realBlack text-white',
              ]"
            >
              <lord-icon trigger="hover" :src="menuItem.iconPath"></lord-icon>
              {{ menuItem.name }}
            </button>
          </router-link>
          <!-- <button
            class="bg-realBlack w-full text-white hover:bg-white hover:text-realBlack flex items-center px-6 py-2 font-roboto gap-4 transition-all duration-500"
          >
            <lord-icon
              trigger="hover"
              src="/assets/lordicon/avatar.json"
            ></lord-icon>
            Users
          </button> -->
          <!-- <button
            class="bg-realBlack bg-op w-full text-white flex items-center px-6 py-4 font-roboto gap-4"
          >
            <lord-icon
              trigger="hover"
              src="/assets/lordicon/home.json"
            ></lord-icon>
            Dashboard
          </button>
          <button
            class="bg-realBlack bg-op w-full text-white flex items-center px-6 py-4 font-roboto gap-4"
          >
            <lord-icon
              trigger="hover"
              src="/assets/lordicon/home.json"
            ></lord-icon>
            Dashboard
          </button>
          <button
            class="bg-realBlack bg-op w-full text-white flex items-center px-6 py-4 font-roboto gap-4"
          >
            <lord-icon
              trigger="hover"
              src="/assets/lordicon/home.json"
            ></lord-icon>
            Dashboard
          </button> -->
        </div>
      </div>
      <div class="w-full">
        <button
          class="bg-realBlack bg-op w-full text-white flex items-center px-6 py-4 font-roboto gap-4"
          @click="logOut"
        >
          <lord-icon
            trigger="hover"
            src="/assets/lordicon/fingerPrint.json"
          ></lord-icon>
          Logout
        </button>
      </div>
    </div>
    <div class="w-10/12 max-h-screen overflow-y-auto">
      <slot />
    </div>
  </div>
</template>

<script>
import DynamicLogo from "./DynamicLogo.vue";
export default {
  components: { DynamicLogo },
  data() {
    return {
      menuItems: [
        {
          name: "Orders",
          link_name: "home",
          iconPath: "/assets/lordicon/home.json",
        },
        {
          name: "Prices",
          link_name: "prices",
          iconPath: "/assets/lordicon/cart.json",
        },
        {
          name: "Admins",
          link_name: "admins",
          iconPath: "/assets/lordicon/avatar.json",
        },
      ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    async logOut() {
      await this.$store.dispatch("logout");
      this.$router.push({ name: "login" });
    },
  },
  // watch: {
  //   currentRouteName() {
  //     console.log(this.currentRouteName);
  //   },
  // },
  // mounted() {
  //   console.log(this.$route);

  //   console.log(this.currentRouteName);
  // },
};
</script>

<style>
</style>
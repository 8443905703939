<template>
  <div
    class="w-full flex items-center justify-center max-h-screen overflow-hidden"
  >
    <div
      class="flex min-w-96 flex-col items-center justify-between min-h-screen max-h-screen overflow-y-auto py-16"
    >
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/svgs/logo.svg" alt="" />
      </router-link>
      <div class="w-full">
        <div class="mb-16">
          <h1
            class="font-black text-4xl text-center font-roboto mb-4 text-black"
          >
            Qoray Admin
          </h1>
          <p class="font-sans text-center text-base">
            Fill in your login information to proceed
          </p>
        </div>
        <div class="mb-6">
          <label class="block text-sm mb-4">
            Email address
            <input
              class="block border text-sm border-grey w-full rounded-lg py-4 px-5 mt-2 border-opacity-50"
              type="email"
              name=""
              id="email"
              placeholder="Enter your email address"
              v-model="data.email"
            />
          </label>
          <label class="block text-sm">
            Password
            <input
              class="block text-sm border border-grey w-full rounded-lg py-4 px-5 mt-2 border-opacity-50"
              type="password"
              name=""
              id="password"
              placeholder="Enter your password"
              v-model="data.password"
            />
          </label>
          <p v-if="error" class="text-red my-2">{{ error }}</p>
        </div>
        <button-with-loader
          buttonText="Login"
          :loading="loading"
          :onClick="clickAction"
        />
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ButtonWithLoader from "@/components/ButtonWithLoader.vue";
export default {
  data() {
    return {
      loading: false,
      data: {
        email: "",
        password: "",
      },
      error: "",
    };
  },
  components: { ButtonWithLoader },
  methods: {
    async clickAction() {
      this.loading = true;
      var payload = {
        data: this.data,
        path: "/admin/auth/login",
      };
      try {
        const response = await this.$store.dispatch("authRequest", payload);
        // console.log(response);
        sessionStorage.setItem("token", response.data.token);
        location.reload();
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          this.error = error.response.data.message;
        } else {
          this.error = "An error ocurred";
        }
      }
      this.loading = false;
      // console.log(e);
      // alert("click");
    },
  },
};
</script>

<style>
</style>
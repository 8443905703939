var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-[90%] max-w-xl mx-auto"},[_c('div',{staticClass:"w-full pt-14"}),_c('div',{staticClass:"w-full mt-10"},[(_vm.loading)?_c('div',{staticClass:"w-full pt-40 flex items-center justify-center"},[_c('spinner')],1):_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full flex items-center justify-between"},[_c('span',[_vm._v("Total Admins: "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.total))])]),_c('button',{staticClass:"bg-realBlack text-white font-medium py-2 px-6 rounded-lg cursor-pointer",on:{"click":function($event){_vm.inviteModal = true}}},[_vm._v(" Invite Admin ")]),(_vm.inviteModal)?_c('InviteAdmin',{attrs:{"closeModal":_vm.closeInvite}}):_vm._e()],1),_c('table',{staticClass:"w-full text-left mt-8"},[_vm._m(0),_vm._l((_vm.orders),function(order,index){return _c('tr',{key:order.id,class:[
                'border-b border-b-grey border-opacity-20 cursor-pointer hover:bg-grey hover:bg-opacity-45 transition-all',
                (index + 1) % 2
                  ? 'bg-grey bg-opacity-20'
                  : 'bg-grey bg-opacity-5',
              ]},[_c('td',{staticClass:"pl-2 py-2 text-center"},[_c('div',{staticClass:"w-12 h-12 rounded-full overflow-hidden"},[_c('img',{staticClass:"w-full object-contain",attrs:{"src":order.picture_url,"alt":""}})])]),_c('td',{staticClass:"py-2 text-left pr-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.formatDate(order.created_at))+" ")]),_c('td',[_vm._v(_vm._s(order.first_name))]),_c('td',[_vm._v(_vm._s(order.last_name))]),_c('td',{staticClass:"py-2"},[_vm._v(_vm._s(order.email))]),_c('td',{staticClass:"pr-2 py-2"},[_vm._v(" "+_vm._s(order.created_by.email)+" ("+_vm._s(order.created_by.first_name + " " + order.created_by.last_name)+") ")])])})],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"pl-2 py-2 text-center"}),_c('th',{staticClass:"py-2 text-left"},[_vm._v("Date")]),_c('th',[_vm._v("First Name")]),_c('th',[_vm._v("Last Name")]),_c('th',{staticClass:"py-2"},[_vm._v("Email")]),_c('th',{staticClass:"pr-2 py-2"},[_vm._v("Invited by")])])
}]

export { render, staticRenderFns }
<template>
  <svg
    width="111"
    height="19"
    viewBox="0 0 111 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6026 16.133H5.96193C2.94434 16.133 0.497986 13.6867 0.497986 10.6691V5.5616C0.497986 2.54401 2.94434 0.0976562 5.96193 0.0976562H16.6026C19.6202 0.0976562 22.0666 2.54401 22.0666 5.5616V10.6691C22.0666 13.6867 19.6202 16.133 16.6026 16.133ZM7.12983 3.68331C5.84947 3.68331 4.81183 4.72095 4.81183 6.00131V10.216C4.81183 11.4958 5.84947 12.534 7.12983 12.534H15.4341C16.7145 12.534 17.7521 11.4964 17.7521 10.216V6.00131C17.7521 4.72095 16.7145 3.68331 15.4341 3.68331H7.12983Z"
      fill="currentColor"
    />
    <path
      d="M21.3554 18.1389C21.628 18.3759 21.4603 18.8239 21.0987 18.8239H17.928C17.928 18.8239 14.9212 18.9465 12.2518 15.7555L18.1923 15.5159C18.2851 15.5121 18.376 15.5439 18.4465 15.6049L21.3554 18.1383V18.1389Z"
      fill="currentColor"
    />
    <path
      d="M41.2434 10.6449C41.2434 11.6877 40.3983 12.5328 39.3556 12.5328H30.1916C29.1489 12.5328 28.3038 11.6877 28.3038 10.6449V5.5705C28.3038 4.52778 29.1489 3.68268 30.1916 3.68268H37.6501C37.9113 3.68268 38.1229 3.47108 38.1229 3.20993V0.570406C38.1229 0.30925 37.9113 0.0976562 37.6501 0.0976562H29.0231C26.2425 0.0976562 23.9886 2.35148 23.9886 5.13206V11.0993C23.9886 13.8798 26.2425 16.1337 29.0231 16.1337H40.5235C43.304 16.1337 45.5579 13.8798 45.5579 11.0993V6.91441C45.5579 6.65325 45.3463 6.44166 45.0851 6.44166H41.7161C41.455 6.44166 41.2434 6.65325 41.2434 6.91441V10.6462V10.6449Z"
      fill="currentColor"
    />
    <path
      d="M58.0026 9.95988H63.1152C65.2959 9.95988 67.0643 8.19152 67.0643 6.01077V4.44574C67.0643 2.08643 65.1517 0.173828 62.7924 0.173828H48.0094C47.7082 0.173828 47.4776 0.453411 47.5443 0.746973C47.7908 1.83163 48.4339 2.72566 49.6653 3.33503C50.2518 3.62541 50.9069 3.75059 51.5608 3.75059H61.2413C63.1489 3.75059 63.1489 6.44349 61.2413 6.44349H48.6652C48.2744 6.44349 48.0946 6.93085 48.3926 7.18438L56.6435 14.2108C57.9588 15.2256 59.499 15.7765 61.1841 16.0275C61.4866 16.0726 61.7929 16.0878 62.0991 16.0878H64.9344C65.2819 16.0878 65.4313 15.6469 65.1555 15.4359L58.0026 9.96052V9.95988Z"
      fill="currentColor"
    />
    <path
      d="M77.4013 0.0416517H81.7265C81.8918 0.0416517 82.0455 0.128068 82.1313 0.269766L91.28 15.3666C91.4707 15.6818 91.2438 16.0847 90.8753 16.0847H86.9141C86.7476 16.0847 86.5932 15.997 86.5074 15.8534L79.5483 4.14899C79.5483 4.14899 79.5471 4.14835 79.5464 4.14899L76.2817 9.70443C76.2817 9.70443 76.2817 9.7057 76.2823 9.7057L74.1994 13.2482C74.1994 13.2482 74.1988 13.2482 74.1981 13.2482L72.6693 15.8502C72.5842 15.9944 72.4291 16.0834 72.2614 16.0834H68.3993C68.032 16.0834 67.8052 15.6837 67.9933 15.3685L76.2753 1.47769L76.9946 0.271672C77.0797 0.128703 77.2341 0.0410156 77.4006 0.0410156"
      fill="currentColor"
    />
    <path
      d="M45.0896 4.2946H40.8437C40.5826 4.2946 40.371 4.08301 40.371 3.82185V0.59775C40.371 0.336594 40.5826 0.125 40.8437 0.125H45.0896C45.3507 0.125 45.5623 0.336594 45.5623 0.59775V3.82185C45.5623 4.08301 45.3507 4.2946 45.0896 4.2946Z"
      fill="#55B848"
    />
    <path
      d="M109.669 0.00127207H105.183C105.035 0.00127207 104.894 0.0654485 104.798 0.177282L99.3853 6.44376C99.1826 6.67887 98.8185 6.67823 98.6158 6.44376L93.2104 0.177282C93.1138 0.0654485 92.9733 0.000636037 92.8253 0.000636037H88.3431C87.9002 0.000636037 87.6689 0.528033 87.9694 0.853366L96.6581 10.2696C96.7445 10.3636 96.7928 10.4863 96.7928 10.6146V15.5969C96.7928 15.8778 97.0203 16.1053 97.3012 16.1053H100.746C101.027 16.1053 101.254 15.8778 101.254 15.5969V10.4158H101.258L110.043 0.852094C110.342 0.526125 110.111 0 109.669 0"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>
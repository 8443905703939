<template>
  <div class="w-full">
    <div class="w-[90%] max-w-xl mx-auto">
      <div class="w-full pt-14">
        <!-- <img src="@/assets/svgs/logo.svg" alt="" /> -->
      </div>
      <div class="w-full mt-10">
        <div
          v-if="loading"
          class="w-full pt-40 flex items-center justify-center"
        >
          <spinner />
        </div>
        <div class="w-full" v-else>
          <div class="w-full pb-20" v-if="singleActive">
            <div class="w-full mt-8">
              <span class="cursor-pointer underline" @click="closeSingleOrder"
                >Back</span
              >
            </div>
            <div class="w-full">
              <h1 class="text-5xl font-semibold">
                {{ singleOrder.vehicle }} Order
              </h1>
              <div class="grid grid-cols-3 gap-3 mt-8">
                <div class="col-span-2">
                  <span class="text-sm text-qorayGreen">Name</span>
                  <div>{{ singleOrder.fullname }}</div>
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Date & Time</span>
                  <div>
                    {{ $store.getters.formatDate(singleOrder.created_at) }}
                  </div>
                </div>
                <div
                  class="font-medium col-span-3 text-2xl pt-6 mt-4 border-t border-t-newGrey border-opacity-25"
                >
                  Order Information
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Quantity</span>
                  <div>{{ singleOrder.quantity }}</div>
                </div>

                <div>
                  <span class="text-sm text-qorayGreen">Colours</span>
                  <div>{{ singleOrder.colours }}</div>
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Delivery</span>
                  <div>{{ singleOrder.deliveryoption }}</div>
                </div>
                <div
                  class="font-medium col-span-3 text-2xl pt-6 mt-4 border-t border-t-newGrey border-opacity-25"
                >
                  Contact Information
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Email</span>
                  <div>{{ singleOrder.email }}</div>
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Phone Number</span>
                  <div>{{ singleOrder.phone }}</div>
                </div>
                <div
                  class="font-medium col-span-3 text-2xl pt-6 mt-4 border-t border-t-newGrey border-opacity-25"
                >
                  Pricing Information
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Price</span>
                  <div>{{ singleOrder.unitprice }}</div>
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Downpayment</span>
                  <div>{{ singleOrder.unitdownpayment }}</div>
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Monthly Payment</span>
                  <div>{{ singleOrder.unitmonthly }}</div>
                </div>
                <div
                  class="font-medium col-span-3 text-2xl pt-6 mt-4 border-t border-t-newGrey border-opacity-25"
                >
                  Payment Information
                </div>

                <div>
                  <span class="text-sm text-qorayGreen">Total Price</span>
                  <div>{{ singleOrder.price }}</div>
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Total Downpayment</span>
                  <div>{{ singleOrder.downpayment }}</div>
                </div>
                <div>
                  <span class="text-sm text-qorayGreen"
                    >Total Monthly Payment</span
                  >
                  <div>{{ singleOrder.monthly }}</div>
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Payment Option</span>
                  <div>{{ singleOrder.paymentoption }}</div>
                </div>
                <div class="col-span-2">
                  <span class="text-sm text-qorayGreen">Duration</span>
                  <div>{{ singleOrder.duration }}</div>
                </div>
                <div
                  class="font-medium col-span-3 text-2xl pt-6 mt-4 border-t border-t-newGrey border-opacity-25"
                >
                  Address
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">Street</span>
                  <div>{{ singleOrder.street }}</div>
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">City</span>
                  <div>{{ singleOrder.city }}</div>
                </div>
                <div>
                  <span class="text-sm text-qorayGreen">State</span>
                  <div>{{ singleOrder.state }}</div>
                </div>
              </div>
            </div>
            <!-- {{ JSON.stringify(singleOrder) }} -->
          </div>
          <!-- <div class="w-full grid grid-cols-4 gap-4" v-else>
            <div class="col-span-4">
              <span
                >Total orders: <span class="font-bold">{{ total }}</span></span
              >
            </div>
            <div
              v-for="order in orders"
              :key="order.id"
              class="shadow-md rounded-lg p-4 transition-all hover:shadow-inner cursor-pointer"
              @click="getSingleOrder(order.id)"
            >
              <div class="w-full flex items-center justify-between mb-2">
                <p class="text-black text-lg font-semibold">
                  {{ order.vehicle }}
                </p>
                <p class="text-xs opacity-35 font-medium font-roboto">
                  {{ $store.getters.formatDate(order.created_at) }}
                </p>
              </div>
              <div class="w-full flex items-start justify-between gap-8">
                <div class="grid grid-cols-2 gap-2 w-full">
                  <div class="col-span-2">
                    <span class="text-sm text-qorayGreen ">Name</span>
                    <div>{{ order.fullname }}</div>
                  </div>
                  <div>
                    <span class="text-sm text-qorayGreen ">Quantity</span>
                    <div>{{ order.quantity }}</div>
                  </div>
                  <div>
                    <span class="text-sm text-qorayGreen ">Payment Option</span>
                    <div>{{ order.paymentoption }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="w-full" v-else>
            <div class="w-full flex items-center justify-between">
              <span
                >Total orders: <span class="font-bold">{{ total }}</span></span
              >
              <div class="">
                <!-- <h4 class="font-medium">Filters</h4> -->
                <div class="flex items-end gap-4">
                  <div>
                    <label class="text-sm text-grey font-medium">
                      Search by Name <br />
                      <input
                        v-model="filters.fullname"
                        placeholder="Enter Search Text"
                        class="px-2 py-1 border border-grey border-opacity-35 rounded-md mt-1"
                      />
                    </label>
                  </div>
                  <div>
                    <label class="text-sm text-grey font-medium">
                      Vehicle <br />
                      <select
                        v-model="filters.vehicle"
                        name=""
                        id=""
                        class="px-2 py-1 border border-grey border-opacity-35 rounded-md mt-1"
                      >
                        <option value="">SELECT VEHICLE</option>
                        <option
                          v-for="veh in filterOptions.vehicles"
                          :key="veh"
                          :value="veh"
                          v-text="veh"
                        ></option>
                      </select>
                    </label>
                  </div>
                  <div>
                    <label class="text-sm text-grey font-medium">
                      Payment Option <br />
                      <select
                        v-model="filters.paymentoption"
                        name=""
                        id=""
                        class="px-2 py-1 border border-grey border-opacity-35 rounded-md mt-1"
                      >
                        <option value="">SELECT PAYMENT OPTION</option>
                        <option
                          v-for="popt in filterOptions.paymentoptions"
                          :key="popt"
                          :value="popt"
                          v-text="popt"
                        ></option>
                      </select>
                    </label>
                  </div>
                  <div
                    v-if="
                      filters.vehicle ||
                      filters.fullname ||
                      filters.paymentoption
                    "
                    class=""
                  >
                    <span
                      @click="resetFilters"
                      class="underline text-xs font-roboto font-light cursor-pointer"
                      >Reset</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <table class="w-full text-left mt-8">
              <tr>
                <th class="py-2 text-center">S/N</th>
                <th>Vehicle</th>
                <th>Full Name</th>
                <th class="py-2 text-center">Payment Option</th>
                <th class="py-2 text-center">Quantity</th>
                <th class="py-2 text-center">Date</th>
                <!-- <th></th> -->
              </tr>
              <tr
                :class="[
                  'border-b border-b-grey border-opacity-20 cursor-pointer hover:bg-grey hover:bg-opacity-45 transition-all',
                  (index + 1) % 2
                    ? 'bg-grey bg-opacity-20'
                    : 'bg-grey bg-opacity-5',
                ]"
                v-for="(order, index) in orders"
                :key="order.id"
                @click="getSingleOrder(order.id)"
              >
                <td class="py-2 text-center">{{ index + 1 }}</td>
                <td>{{ order.vehicle }}</td>
                <td>{{ order.fullname }}</td>
                <td class="py-2 text-center">{{ order.paymentoption }}</td>
                <td class="py-2 text-center">{{ order.quantity }}</td>
                <td class="py-2 text-center pr-4">
                  {{ $store.getters.formatDate(order.created_at) }}
                </td>
                <!-- <td class="py-2 pr-4 text-right">
                  <button
                    @click="getSingleOrder(order.id)"
                    class="shadow-md rounded-lg px-4 py-2 text-xs font-roboto font-medium transition-all hover:shadow-inner cursor-pointer"
                  >
                    Open
                  </button>
                </td> -->
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
export default {
  name: "HomeView",
  data() {
    return {
      orders: [],
      filterOptions: {},
      filters: {
        fullname: "",
        vehicle: "",
        paymentoption: "",
      },
      singleOrder: {},
      loading: true,
      total: 0,
      singleActive: false,
    };
  },
  computed: {
    filter() {
      const queryParams = Object.entries(this.filters)
        .filter(([key, value]) => value) // Filter out entries where the value is an empty string
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        ) // Encode key and value
        .join("&");
      return queryParams ? `?${queryParams}` : "";
    },
  },
  watch: {
    filter() {
      // console.log(this.filter);
      this.getOrders(this.filter);
    },
  },
  components: { Spinner },
  methods: {
    async getSingleOrder(id) {
      this.loading = true;
      var payload = { path: `/admin/api/orders/${id}` };
      try {
        const response = await this.$store.dispatch("getRequest", payload);
        this.singleOrder = response.data.data.order;
        // console.log(this.singleOrder);

        this.singleActive = true;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    resetFilters() {
      this.filters = {
        fullname: "",
        vehicle: "",
        paymentoption: "",
      };
    },
    closeSingleOrder(e) {
      this.singleOrder = {};
      this.singleActive = false;
    },
    async getOrders(filters = "") {
      // this.loading = true;
      var payload = { path: "/admin/api/orders" + filters };
      const response = await this.$store.dispatch("getRequest", payload);
      this.orders = response.data.data.orders;
      this.filterOptions = response.data.data.filter_options;
      this.total = response.data.data.total_orders;
      this.loading = false;
    },
  },
  async mounted() {
    await this.getOrders();
    // window.addEventListener("popstate", (event) => {
    //   event.preventDefault();
    //   history.pushState(null, null, window.location.href);
    //   console.log(event);
    // });
  },
};
</script>

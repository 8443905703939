<template>
  <router-view v-if="authPage" />
  <DashboardLayout v-else>
    <router-view />
  </DashboardLayout>
</template>

<style lang="scss">
</style>

<script>
import DashboardLayout from "./components/DashboardLayout.vue";
export default {
  components: { DashboardLayout },
  data() {
    return {};
  },
  computed: {
    authPage() {
      return this.$route.fullPath.includes("/auth/");
    },
  },
  // mounted() {
  //   console.log(this.authPage);
  // },
  // watch: {
  //   authPage() {
  //     console.log(this.authPage);
  //   },
  // },
};
</script>
<style>
* {
  box-sizing: border-box;
}
</style>
<template>
  <div class="w-full">
    <div class="w-[90%] max-w-xl mx-auto">
      <div class="w-full pt-14">
        <!-- <img src="@/assets/svgs/logo.svg" alt="" /> -->
      </div>
      <div class="w-full mt-10">
        <div
          v-if="loading"
          class="w-full pt-40 flex items-center justify-center"
        >
          <spinner />
        </div>
        <div class="w-full" v-else>
          <!-- <div class="w-full grid grid-cols-4 gap-4" v-else>
            <div class="col-span-4">
              <span
                >Total orders: <span class="font-bold">{{ total }}</span></span
              >
            </div>
            <div
              v-for="order in orders"
              :key="order.id"
              class="shadow-md rounded-lg p-4 transition-all hover:shadow-inner cursor-pointer"
              @click="getSingleOrder(order.id)"
            >
              <div class="w-full flex items-center justify-between mb-2">
                <p class="text-black text-lg font-semibold">
                  {{ order.vehicle }}
                </p>
                <p class="text-xs opacity-35 font-medium font-roboto">
                  {{ $store.getters.formatDate(order.created_at) }}
                </p>
              </div>
              <div class="w-full flex items-start justify-between gap-8">
                <div class="grid grid-cols-2 gap-2 w-full">
                  <div class="col-span-2">
                    <span class="text-sm text-realBlack ">Name</span>
                    <div>{{ order.fullname }}</div>
                  </div>
                  <div>
                    <span class="text-sm text-realBlack ">Quantity</span>
                    <div>{{ order.quantity }}</div>
                  </div>
                  <div>
                    <span class="text-sm text-realBlack ">Payment Option</span>
                    <div>{{ order.paymentoption }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="w-full">
            <div class="w-full flex items-center justify-between">
              <span
                >Total Admins: <span class="font-bold">{{ total }}</span></span
              >
              <button
                @click="inviteModal = true"
                class="bg-realBlack text-white font-medium py-2 px-6 rounded-lg cursor-pointer"
              >
                Invite Admin
              </button>
              <InviteAdmin v-if="inviteModal" :closeModal="closeInvite" />
            </div>
            <table class="w-full text-left mt-8">
              <tr>
                <th class="pl-2 py-2 text-center"></th>
                <th class="py-2 text-left">Date</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th class="py-2">Email</th>
                <th class="pr-2 py-2">Invited by</th>
                <!-- <th class="py-2 text-center">Instagram</th>
                <th class="py-2 text-center">Tiktok</th>
                <th class="py-2 text-center">X</th>
                <th class=" py-2 text-center">Facebook</th> -->
                <!-- <th></th> -->
              </tr>
              <tr
                :class="[
                  'border-b border-b-grey border-opacity-20 cursor-pointer hover:bg-grey hover:bg-opacity-45 transition-all',
                  (index + 1) % 2
                    ? 'bg-grey bg-opacity-20'
                    : 'bg-grey bg-opacity-5',
                ]"
                v-for="(order, index) in orders"
                :key="order.id"
              >
                <td class="pl-2 py-2 text-center">
                  <div class="w-12 h-12 rounded-full overflow-hidden">
                    <img
                      :src="order.picture_url"
                      alt=""
                      class="w-full object-contain"
                    />
                  </div>
                </td>
                <td class="py-2 text-left pr-4">
                  {{ $store.getters.formatDate(order.created_at) }}
                </td>
                <td>{{ order.first_name }}</td>
                <td>{{ order.last_name }}</td>
                <td class="py-2">{{ order.email }}</td>
                <td class="pr-2 py-2">
                  {{ order.created_by.email }} ({{
                    order.created_by.first_name +
                    " " +
                    order.created_by.last_name
                  }})
                </td>
                <!-- <td class="py-2 text-center">{{ order.instagram }}</td>
                <td class="py-2 text-center">{{ order.tiktok }}</td>
                <td class="py-2 text-center">{{ order.x }}</td>
                <td class="py-2 text-center">{{ order.facebook }}</td> -->
                <!-- <td class="py-2 pr-4 text-right">
                  <button
                    @click="getSingleOrder(order.id)"
                    class="shadow-md rounded-lg px-4 py-2 text-xs font-roboto font-medium transition-all hover:shadow-inner cursor-pointer"
                  >
                    Open
                  </button>
                </td> -->
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import InviteAdmin from "@/components/InviteAdmin.vue";
export default {
  name: "HomeView",
  data() {
    return {
      orders: [],
      filterOptions: {},
      filters: {
        fullname: "",
        vehicle: "",
        paymentoption: "",
      },
      singleOrder: {},
      loading: true,
      total: 0,
      singleActive: false,
      inviteModal: false,
    };
  },
  computed: {
    filter() {
      const queryParams = Object.entries(this.filters)
        .filter(([key, value]) => value) // Filter out entries where the value is an empty string
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        ) // Encode key and value
        .join("&");
      return queryParams ? `?${queryParams}` : "";
    },
  },
  watch: {
    filter() {
      // console.log(this.filter);
      this.getOrders(this.filter);
    },
  },
  components: { Spinner, InviteAdmin },
  methods: {
    closeInvite() {
      this.inviteModal = false;
    },

    resetFilters() {
      this.filters = {
        fullname: "",
        vehicle: "",
        paymentoption: "",
      };
    },
    closeSingleOrder(e) {
      this.singleOrder = {};
      this.singleActive = false;
    },
    async getOrders(filters = "") {
      // this.loading = true;
      var payload = { path: "/admin/api/admins" + filters };
      const response = await this.$store.dispatch("getRequest", payload);
      this.orders = response.data.data.admins;
      // this.filterOptions = response.data.data.filter_options;
      this.total = response.data.data.total_admins;
      this.loading = false;
    },
  },
  async mounted() {
    await this.getOrders();
    // window.addEventListener("popstate", (event) => {
    //   event.preventDefault();
    //   history.pushState(null, null, window.location.href);
    //   console.log(event);
    // });
  },
};
</script>

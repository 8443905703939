<template>
  <div
    class="fixed w-full min-h-screen max-h-screen flex items-center justify-center bg-black bg-opacity-30 top-0 left-0 right-0"
  >
    <div class="p-6 w-11/12 rounded-lg max-w-md bg-white font-bold">
      <h3 class="text-center text-xl">Invite Admin</h3>
      <div class="w-full flex items-center justify-between gap-3">
        <div class="w-1/2">
          <input
            type="text"
            placeholder="First Name"
            v-model="first_name"
            class="font-normal border border-realBlack border-opacity-40 w-full py-2 px-6 rounded-md mt-7"
          />
        </div>
        <div class="w-1/2">
          <input
            type="text"
            placeholder="Last Name"
            v-model="last_name"
            class="font-normal border border-realBlack border-opacity-40 w-full py-2 px-6 rounded-md mt-7"
          />
        </div>
      </div>
      <input
        type="email"
        placeholder="Enter recipient email"
        v-model="email"
        class="font-normal border border-realBlack border-opacity-40 w-full py-2 px-6 rounded-md mt-3"
      />
      <div class="flex justify-between mt-5 gap-3">
        <button
          @click="submit"
          class="bg-realBlack border-realBlack w-1/2 flex items-center justify-center text-white font-medium py-2 px-6 rounded-lg cursor-pointer"
        >
          <Spinner v-if="sending" />
          <span v-else> Send Invitation </span>
        </button>
        <button
          @click="closeModal()"
          class="bg-gray-500 text-white w-1/2 font-medium py-2 px-6 rounded-lg cursor-pointer"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "./Spinner.vue";
export default {
  components: { Spinner },
  props: ["closeModal"],
  data() {
    return {
      first_name: "",
      sending: false,
      last_name: "",
      email: "",
    };
  },
  methods: {
    isValidEmail(email) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(email);
    },
    async submit() {
      if (this.first_name.length < 2) {
        return alert("first name is too short");
      }
      if (this.last_name.length < 2) {
        return alert("last name is too short");
      }
      if (!this.isValidEmail(this.email)) {
        return alert("Invalid Email");
      }
      var payload = {
        path: "/admin/auth/register",
        data: {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
        },
      };
      try {
        this.sending = true;
        const response = await this.$store.dispatch("postRequest", payload);
        alert(response.data.message);
        this.closeModal();
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          alert(error.response.data.message);
        } else {
          alert("An error ocurred");
        }
      }
      this.sending = false;
    },
  },
};
</script>

<style>
</style>
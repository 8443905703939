import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Prices from '../views/Prices.vue'
import Admins from '../views/Admins.vue'
import AuthLayout from '../views/auth/Layout.vue'
import Login from '../views/auth/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/prices',
    name: 'prices',
    component: Prices,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/admins',
    name: 'admins',
    component: Admins,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    meta: {
      requireAuth: false
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          requireAuth: false
        },
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((a, b, next) => {
  const isLoggedIn = !!sessionStorage.getItem('token')
  if (!isLoggedIn && a.meta.requireAuth) {
    router.push({ name: 'login' });
  } else if (a.name === 'login' && isLoggedIn) {
    router.push({ name: 'home' });
  }
  next()
})

export default router

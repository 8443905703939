<template>
  <button
    class="flex items-center justify-center w-full bg-black text-white pt-3 pb-4 text-sm rounded-full"
    @click="onClick"
  >
    <Spinner v-if="loading" />
    <span v-else>{{ buttonText }}</span>
  </button>
</template>

<script>
import Spinner from "./Spinner.vue";
export default {
  components: { Spinner },
  props: ["buttonText", "loading", "onClick"],
};
</script>

<style>
</style>
<template>
  <div class="py-20 w-[90%] mx-auto">
    <div class="w-full">
      <h1 class="text-5xl font-semibold">Product Prices</h1>
      <div v-if="loading" class="w-full pt-40 flex items-center justify-center">
        <spinner />
      </div>
      <div class="w-full mt-6" v-else>
        <h2 class="text-3xl font-medium">Current Prices</h2>
        <div class="w-full grid md:grid-cols-3 gap-8 mt-4">
          <div v-for="col in cols" :key="col" class="w-full">
            <h4 class="capitalize text-xl font-semibold">{{ col }}</h4>
            <div class="w-full flex items-center mt-3 gap-3">
              <label class="block w-1/2">
                Outright (NGN)
                <input
                  type="number"
                  class="py-1 cursor-not-allowed px-3 border border-opacity-40 mt-2 rounded-md w-full"
                  placeholder="Outright"
                  disabled
                  :value="current[col + '_outright']"
                />
              </label>
              <label class="block w-1/2">
                Monthly (NGN)
                <input
                  type="number"
                  placeholder="Monthly"
                  class="py-1 cursor-not-allowed px-3 border border-opacity-40 mt-2 rounded-md w-full"
                  disabled
                  :value="current[col + '_monthly']"
                />
              </label>
            </div>
          </div>
          <div class="col-span-full">
            <p class="text-right">
              Updated on: {{ $store.getters.formatDate(current.updated_at) }}.
              By: {{ current?.admin?.email }} ({{
                current?.admin?.first_name + " " + current?.admin?.last_name
              }})
            </p>
          </div>
        </div>
        <div class="w-full mt-20 flex items-center justify-between">
          <h2 class="text-3xl font-medium">Update Prices</h2>
          <button
            class="bg-realBlack text-white font-medium py-2 flex items-center justify-center px-4 rounded-md mt-6"
            @click="update ? (update = false) : openModel()"
          >
            {{ update ? "Close" : "Open" }} Update Form
          </button>
        </div>
        <div ref="updateForm" class="w-full">
          <div v-if="update" class="w-full grid md:grid-cols-3 gap-8 mt-4">
            <div v-for="col in cols" :key="col" class="w-full">
              <h4 class="capitalize text-xl font-semibold">{{ col }}</h4>
              <div class="w-full flex items-center mt-3 gap-3">
                <label class="block w-1/2">
                  Outright (NGN)
                  <input
                    type="number"
                    class="py-1 px-3 border border-opacity-40 mt-2 rounded-md w-full"
                    placeholder="Outright"
                    v-model="prices[col + '_outright']"
                  />
                </label>
                <label class="block w-1/2">
                  Monthly (NGN)
                  <input
                    type="number"
                    placeholder="Monthly"
                    class="py-1 px-3 border border-opacity-40 mt-2 rounded-md w-full"
                    v-model="prices[col + '_monthly']"
                  />
                </label>
              </div>
            </div>
            <div class="col-span-full flex justify-end">
              <button
                :disabled="saving"
                @click="postPrices"
                class="bg-realBlack text-white font-medium py-2 flex items-center justify-center px-4 rounded-md mt-6"
              >
                <Spinner v-if="saving" />
                <span v-else>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
export default {
  components: { Spinner },
  data() {
    return {
      loading: true,
      saving: false,
      update: false,
      current: {},
      prices: {},
      cols: [
        "panther",
        "savannah",
        "sill",
        "caspian",
        "sable",
        "javan",
        "beluga",
        "teak",
        "silene",
        "falcon",
        "ferret",
      ],
    };
  },
  methods: {
    openModel() {
      this.update = true;
      setTimeout(() => {
        const ele = this.$refs.updateForm;
        ele.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 500);
    },
    async getPrices() {
      var payload = { path: "/admin/api/prices" };
      const response = await this.$store.dispatch("getRequest", payload);
      this.current = JSON.parse(JSON.stringify(response.data.data));
      this.prices = JSON.parse(JSON.stringify(response.data.data));
      this.loading = false;
    },
    async postPrices() {
      if (!this.saving) {
        this.saving = true;
        var payload = { path: "/admin/api/prices", data: this.prices };

        delete payload.data.id;
        delete payload.data.admin;
        delete payload.data.updated_by;
        delete payload.data.updated_at;

        const response = await this.$store.dispatch("postRequest", payload);
        alert(response.data.message);
        this.current = JSON.parse(JSON.stringify(response.data.data));
        this.prices = JSON.parse(JSON.stringify(response.data.data));
        this.saving = false;
      }
    },
  },
  async mounted() {
    await this.getPrices();
  },
};
</script>

<style>
</style>